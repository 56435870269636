<template>
    <div class="mainSpyContainer">
        <BosArazi></BosArazi>
    </div>
</template>

<script>
    import BosArazi from './BosArazi';

    export default {
        components: {
            BosArazi
        }
    }
</script>



